import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbBanner from "../components/BreadcrumbBanner";
import SectionTitle from "../components/SectionTitle";
import * as React from "react";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
// import GoogleMapStyle from "../feature/contact/GoogleMapStyle";
import { ContactForm } from "../feature/contact/ContactForm";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact Phone Number</h4>
                    <p>
                      <a href="tel:+441256578017">+44 (0)1256 578 017</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href="mailto:contact@huebox.co.uk">
                        contact@huebox.co.uk
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Location</h4>
                    <p>
                      Unit 7, Fosters Business Park, <br />
                      Old School Road, <br />
                      Hook, <br />
                      Hampshire, RG27 9NY,
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        {/*<div className="col-lg-7">*/}
        {/*  <ContactForm formStyle="contact-form-1" />*/}
        {/*</div>*/}
        {/*<div className="col-lg-5 mt_md--30 mt_sm--30">*/}
        {/*  <GoogleMapStyle />*/}
        {/*</div>*/}
        <div className="col-lg-12">
          <ContactForm formStyle="contact-form-1" />
        </div>
      </div>
    </>
  );
};

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <Layout hideContactBanner>
        <BreadcrumbBanner
          title="Get in touch!"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title="We'd love to hear from you."
                    description="To discuss a bespoke project, or find out more information about our products, please get in touch!"
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
